import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegisterView from "@/views/RegisterView.vue";
import AdminUserSettingsView from "@/views/AdminUserSettingsView.vue";
import AdminMainView from "@/views/administrative_ui/main/AdminMainView.vue";

const routes = [
	// {
	// 	path: "/",
	// 	name: "admin",
	// 	component: AdminMainView,
	// },
	{
		path: "/register",
		name: "register",
		component: RegisterView,
	},
	// {
	// 	path: "/admin_settings",
	// 	name: "admin settings",
	// 	component: AdminUserSettingsView,
	// },
	{
		path: "/register/:hash",
		name: "instance settings",
		component: AdminUserSettingsView,
	},

	// // UI admin
	// {
	// 	path: "/register/:hash",
	// 	name: "instance settings",
	// 	component: AdminUserSettingsView,
	// },

	{
		path: "/",
		// path: "/2ead75b4-000f-5000-b000-1c97c84181bb",
		name: "admin",
		component: AdminMainView,

		children: [
			// login
			{
				path: "/login",
				// path: "/2ead75b4-000f-5000-b000-1c97c84181bb",
				name: "login",
				components: {
					admin: () => import("../views/administrative_ui/login/LoginView.vue"),
				},
			},

			// invoice
			{
				path: "/",
				// path: "/2ead75b4-000f-5000-b000-1c97c84181bb",
				name: "dashboard",
				components: {
					admin: () => import("../views/administrative_ui/dashboard/DashboardView.vue"),
				},
			},
			// invoice
			{
				path: "invoices",
				name: "invoices",
				components: {
					admin: () => import("../views/administrative_ui/invoice/InvoiceListView.vue"),
				},
			},
			{
				path: "invoices/:id",
				name: "invoice",
				components: {
					admin: () => import("../views/administrative_ui/invoice/InvoiceCard.vue"),
				},
			},
			// payment_transaction
			{
				path: "payment_transactions",
				name: "payment_transactions",
				components: {
					admin: () => import("../views/administrative_ui/payment_transaction/PaymentTransactionListView.vue"),
				},
			},
			{
				path: "payment_transactions/:id",
				name: "payment_transaction",
				components: {
					admin: () => import("../views/administrative_ui/payment_transaction/PaymentTransactionCard.vue"),
				},
			},
			// payment_account
			{
				path: "payment_accounts",
				name: "payment_accounts",
				components: {
					admin: () => import("../views/administrative_ui/payment_account/PaymentAccountListView.vue"),
				},
			},
			{
				path: "payment_accounts/:id",
				name: "payment_account",
				components: {
					admin: () => import("../views/administrative_ui/payment_account/PaymentAccountCard.vue"),
				},
			},
			// subscription_type
			{
				path: "subscription_types",
				name: "subscription_types",
				components: {
					admin: () => import("../views/administrative_ui/subscription_type/SubscriptionTypeListView.vue"),
				},
			},
			{
				path: "subscription_types/:id",
				name: "subscription_type",
				components: {
					admin: () => import("../views/administrative_ui/subscription_type/SubscriptionTypeListView.vue"),
				},
			},
			// registration
			{
				path: "registrations",
				name: "registrations",
				components: {
					admin: () => import("../views/administrative_ui/registration/RegistrationListView.vue"),
				},
			},
			{
				path: "registrations/:id",
				name: "registration",
				components: {
					admin: () => import("../views/administrative_ui/registration/RegistrationCard.vue"),
				},
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
